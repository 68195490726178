
import { defineComponent } from 'vue';
import { Accordion, Card, CheckboxMultipleSelect } from '@/ui/index';
import store from '@/store/index';

export default defineComponent({
    name: "Personalization",
    components: { Accordion, Card, CheckboxMultipleSelect },
    data(){
        return {
            store,
            projectListFields:[{uid:0, title:'Auftragsnummer'}, {uid:1, title:'Titel'}, {uid:2, title:'Kunde'}],
            form:{
                projectListFields: [] as Array<any>
            }
        }
    },
    methods:{
        handleShowCreateForm(){
            this.$router.push('/administration/vehicle/form/0')
        },
    }
})
