import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "timi-personalization-container" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "ww-accordion-button" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "ww-accordion-item-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!
  const _component_CheckboxMultipleSelect = _resolveComponent("CheckboxMultipleSelect")!
  const _component_Accordion = _resolveComponent("Accordion")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('personalization.viewPersonalization')), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('personalization.viewPersonalizationText')), 1)
    ]),
    _createVNode(_component_Accordion, null, {
      default: _withCtx(() => [
        _createElementVNode("button", _hoisted_3, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('project.title')), 1),
          _createElementVNode("i", {
            class: "svgicon",
            innerHTML: _ctx.store.getters.svgIcons.arrowdown
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Card, {
            title: _ctx.$t('personalization.general')
          }, null, 8, ["title"]),
          _createVNode(_component_Card, {
            title: _ctx.$t('personalization.projectList')
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('personalization.projectListDescription')), 1),
              _createVNode(_component_CheckboxMultipleSelect, {
                options: _ctx.projectListFields,
                value: [],
                onHandleSetArray: _cache[0] || (_cache[0] = (value)=> _ctx.form.projectListFields=value)
              }, null, 8, ["options"])
            ]),
            _: 1
          }, 8, ["title"])
        ])
      ]),
      _: 1
    })
  ]))
}